@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .color-primary {
    color: mat.get-color-from-palette($primary-palette, 500) !important;
  }

  .content-header,
  .content-header-mobile {
    background: mat.get-color-from-palette($primary-palette, 500);
  }

  .header-menu:focus {
    color: mat.get-color-from-palette($primary-palette, 500);
    background-color: mat.get-color-from-palette($primary-palette, 50);
  }

  .navigation-select {
    border-color: mat.get-color-from-palette($primary-palette, 500) !important;
    background-color: mat.get-color-from-palette($primary-palette, 50);
  }

  .mat-tab-label-active {
    color: mat.get-color-from-palette($primary-palette, 500) !important;
  }

  .mat-stroked-button {
    border-color: mat.get-color-from-palette($primary-palette, 500) !important;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
